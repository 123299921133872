import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import brands from '../../../config/brands.json';
import Grid from 'components/LinkCard/Grid';
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Chameleon is a collection of reusable tools and components that are divided into
multiple packages. They can be used together or separately depending on your
team’s needs.`}</p>
    <h2 {...{
      "id": "themes",
      "style": {
        "position": "relative"
      }
    }}>{`Themes`}</h2>
    <p>{`A theme is a combination of multiple assets that define the foundations of a
brands identity. This includes tokens, icons, logos, illustrations and fonts for
different platforms, in our case we support iOS, Android and Web.`}</p>
    <h3 {...{
      "id": "design-tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Design Tokens`}</h3>
    <p>{`Tokens are a set of conventions translated into variables. They are meant to
make the converstation between design and develop as seamless as possible.
Tokens are defined in a JSON format and transformed via
`}<a parentName="p" {...{
        "href": "https://amzn.github.io/style-dictionary/"
      }}>{`Amazons style-dictionary`}</a>{` cli for
every platform to consume. The basic set of tokens are for every brand the same,
on top of that we provide for each theme specific component tokens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "wrap=none",
        "wrap": "none"
      }}>{`$button-outline-hover-border-color: #0088c2;
// ...
$color-grey-base: #d7d7d7;
$color-primary-base: #e20714;
// ...
`}</code></pre>
    <h3 {...{
      "id": "icons",
      "style": {
        "position": "relative"
      }
    }}>{`Icons`}</h3>
    <p>{`Every brand has the same default icon set. This default set can be augmented
with the brand's own identity. We provide icons for every platform in different
formats: PDF for iOS, XML Drawables for Android & React components and
SVG(sprites) for web.`}</p>
    <h3 {...{
      "id": "logos",
      "style": {
        "position": "relative"
      }
    }}>{`Logo's`}</h3>
    <p>{`Logos are the visual identity of each brand. We provide at least BrandMain,
BrandSquareMain & BrandPlusMain, but several other variations are commonly
available. We provide logos for every platform in different formats: PDF for
iOS, XML Drawables for Android & React components and SVG(sprites) for web.`}</p>
    <h3 {...{
      "id": "favicons",
      "style": {
        "position": "relative"
      }
    }}>{`Favicons`}</h3>
    <p>{`Favicons have a different implementation for each platform, we support a basic
set, which should be enough.`}</p>
    <h3 {...{
      "id": "fonts",
      "style": {
        "position": "relative"
      }
    }}>{`Fonts`}</h3>
    <p>{`Fonts will be predefined for each brand. There will always be a brand-primary,
brand-secondary and a system font. We use only 3 weights of each font: regular,
medium & bold. For every platform the fonts will be served via the theme
packages. They are also provided via a CDN.`}</p>
    <h3 {...{
      "id": "active-themes-packages",
      "style": {
        "position": "relative"
      }
    }}>{`Active themes-packages`}</h3>
    <div>
  <Grid items={brands.filter(({
        active
      }) => active).map(({
        name,
        abbreviation
      }) => {
        return {
          title: name,
          subtitle: `@mediahuis/chameleon-theme-${abbreviation}`,
          path: `https://dev.azure.com/mediahuis/DNM/_artifacts/feed/pulse/Npm/@mediahuis%2Fchameleon-theme-${abbreviation}`,
          target: '_blank',
          external: true
        };
      })} mdxType="Grid" />
    </div>
    <h2 {...{
      "id": "react-project",
      "style": {
        "position": "relative"
      }
    }}>{`React project`}</h2>
    <p>{`By default the components use `}<inlineCode parentName="p">{`chameleon-theme-wl`}</inlineCode>{`. This means, you need to
install this package for every project. The component library itself can be
found in `}<inlineCode parentName="p">{`chameleon-react`}</inlineCode>{`.`}</p>
    <List mb={7} mdxType="List">
  <ListItem title="Check the latest version of chameleon-react" to="https://dev.azure.com/mediahuis/DNM/_artifacts/feed/pulse/Npm/@mediahuis%2Fchameleon-react/" description="" mdxType="ListItem" />
    </List>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=none",
        "wrap": "none"
      }}>{`yarn add @mediahuis/chameleon-theme-wl
yarn add @mediahuis/chameleon-react
`}</code></pre>
    <h3 {...{
      "id": "reset",
      "style": {
        "position": "relative"
      }
    }}>{`Reset`}</h3>
    <p>{`In most cases the reset should be available within the main website layout. No
need to add them.`}</p>
    <p>{`If that's not the case, simply include the reset css to the entry of your app.`}</p>
    <p><inlineCode parentName="p">{`import '@mediahuis/chameleon-reset';`}</inlineCode></p>
    <h3 {...{
      "id": "fonts-1",
      "style": {
        "position": "relative"
      }
    }}>{`Fonts`}</h3>
    <p>{`In most cases the fonts should be available within the main website layout. No
need to add them.`}</p>
    <p>{`If that's not the case, simply add the fonts css to the entry of your app.`}</p>
    <p><inlineCode parentName="p">{`import '@mediahuis/chameleon-theme-wl/lib/web/fonts.css';`}</inlineCode></p>
    <h3 {...{
      "id": "installation-with-mediahuisscripts",
      "style": {
        "position": "relative"
      }
    }}>{`Installation with @mediahuis/scripts`}</h3>
    <h4 {...{
      "id": "multibranded-setup",
      "style": {
        "position": "relative"
      }
    }}>{`Multibranded setup`}</h4>
    <p>{`By default chameleon comes only with the white-label theme. To support another
brand we need to install the brand's corresponding theme package e.g.
`}<inlineCode parentName="p">{`yarn add @mediahuis/chameleon-theme-<name>`}</inlineCode>{`. To make it work in our project, we
need to use extension & alias resolving. In `}<inlineCode parentName="p">{`@mediahuis/scripts`}</inlineCode>{` it's as simple
as adding the following to your `}<inlineCode parentName="p">{`mediahuis-scripts.config.js`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`MH_BRAND`}</inlineCode>{`: The active brand/theme in your project e.g. `}<inlineCode parentName="p">{`hbvl`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=none",
        "wrap": "none"
      }}>{`// mediahuis-scripts.config.js

module.exports = {
  include: [
    '@mediahuis/chameleon-react',
    \`@mediahuis/chameleon-theme-\${process.env.MH_BRAND || 'wl'}\`,
  ],
  extensionVars: {
    source: [process.env.MH_BRAND],
  },
};
`}</code></pre>
    <h3 {...{
      "id": "installation-without-mediahuisscripts",
      "style": {
        "position": "relative"
      }
    }}>{`Installation without @mediahuis/scripts`}</h3>
    <h4 {...{
      "id": "multibranded-setup-1",
      "style": {
        "position": "relative"
      }
    }}>{`Multibranded Setup`}</h4>
    <p>{`If you need to develop for multiple brands, you can replace the
`}<inlineCode parentName="p">{`@mediahuis/chameleon-theme-wl`}</inlineCode>{` imports with one of the earlier mentioned
theme-packages via alias & extension resolving. In webpack it looks something
like this:`}</p>
    <p><inlineCode parentName="p">{`MH_BRAND`}</inlineCode>{`: The active theme in your project e.g. `}<inlineCode parentName="p">{`hbvl`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=none",
        "wrap": "none"
      }}>{`// webpack.config.js

module.exports = {
  //...
  resolve: {
    alias: {
      \`@mediahuis/chameleon-theme-wl\`: \`@mediahuis/chameleon-theme-\${process.env.MH_BRAND}\`,
    },
    extensions: [\`.\${process.env.MH_BRAND}.js\`, '.js'],
  }
};
`}</code></pre>
    <h3 {...{
      "id": "postcss-configuration-required",
      "style": {
        "position": "relative"
      }
    }}>{`PostCSS configuration (required)`}</h3>
    <p><inlineCode parentName="p">{`chameleon-react`}</inlineCode>{` components make use of `}<inlineCode parentName="p">{`@custom-media`}</inlineCode>{` rules. In order to make
this work, you have to use `}<a parentName="p" {...{
        "href": "https://github.com/postcss/postcss"
      }}>{`PostCSS`}</a>{` in
conjunction with our custom PostCSS plugin `}<inlineCode parentName="p">{`chameleon-postcss-plugin`}</inlineCode>{`. Use the
following configuration to hook it up.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=none",
        "wrap": "none"
      }}>{`// postcss.config.js

module.exports = {
  plugins: [
    require('@mediahuis/chameleon-postcss-plugin')({
      theme: process.env.MH_BRAND,
    }),
  ],
};
`}</code></pre>
    <h2 {...{
      "id": "components",
      "style": {
        "position": "relative"
      }
    }}>{`Components`}</h2>
    <p>{`Components provided via `}<inlineCode parentName="p">{`@mediahuis/chameleon-react`}</inlineCode>{` are built with
accessibility in mind. Internally they are built with css-modules and inline
styles for dynamic values. The API is inspired by styled-system with the
box-pattern in mind.`}</p>
    <List mdxType="List">
  <ListItem title="Checkout our react components" to="/components" description="" mdxType="ListItem" />
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      